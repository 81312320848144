import React, { useContext, useEffect } from "react";
import { useNavigate } from "../hooks";
import { UserAuthContext } from "../context";
import Spinner from "react-bootstrap/Spinner";

const Logout = () => {
  const { isAuthenticated, removeUser } = useContext(UserAuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) return;

    removeUser();
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <Spinner animation="border" color="primary" />
    </>
  );
};

export default Logout;
